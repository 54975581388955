import homeTestimonial from "../images/testimonials/home-testimonial.jpg";
import chadPicture from "../images/team/testimonials/chad.jpg";
import stephaniePicture from "../images/team/testimonials/stephanie.jpg";
import brianPicture from "../images/team/testimonials/brian.jpg";
import rodneyPicture from "../images/team/testimonials/rodney.jpg";

const content = {
  globals: {
    phoneNumber: "tel:+1 727 733 8520",
  },
  navbar: {
    home: "Home",
    team: "Team",
    company: "Company",
    training: "Training",
    faq: "FAQ",
    positions: "Positions",
    contacts: "Contact Us",
    btn: "Call now",
    cta: "tel:+1 727 733 8520",
  },
  components: {
    press: { title: "As seen on" },
  },
  pages: {
    home: {
      header: {
        //"intro": 'American Power & Gas <span class="color-secondary">Careers</span>',
        title:
          'Join the American Power & Gas <span class="color-secondary">Team</span>',
        paragraphs: [
          "This can be your New Home! The job is tough and it takes dedication, but for those that are willing to put in the work, the rewards are great. Check our staff's earning results, listen to their comments, feel the atmosphere of one of the fastest growing companies in Florida.",
        ],
        button: "Get Started",
        video: "https://www.youtube.com/embed/49X-uXzXC_c",
      },
      testimonials: {
        title: "Our Staff, Our Team, Our Success",
        paragraphs: [
          "Our paid training will teach you skills for life.",
          "Many of our employees reach financial goals they never had before. Listen to their words.",
        ],
        videos: [
          {
            name: "Stephanie",
            position: "Sales Representative | currently employed",
            url: "https://www.youtube.com/embed/kPjIDIBy2lA?rel=0",
            thumbnail: homeTestimonial,
          },
        ],
      },
      features: [
        {
          title: "Stable",
          text:
            "We know learning a new job or starting a new career can be daunting. For this reason we have created a complete training system to take you from wherever you are to the level of a PRO. We are bringing innovation to one of the most stable industries in the world.",
        },
        {
          title: "Fast Growing",
          text:
            "American Power & Gas is one of the fastest growing energy company in the industry. And this is thanks to the strength of our team. We are looking for you. You don’t need to have any expertise, we only need you to show up, be determined and work hard. The rest will come.                    ",
        },
        {
          title: "Ethical",
          text:
            "We pride ourselves to run an extremely ethical team. All our employees adhere to a code of conduct that requires total professionalism, respect, courtesy and kindness both toward the customers as well as internally.",
        },
      ],
      quote: {
        text:
          "The goal of APG is to develop a group of powerful individuals who work together and create a business environment where each one of the team can demonstrate great competence and worthy of the complete respect, trust, admiration and unwavering support of their fellow workers.",
        name: "Tom Cummins",
        position: "C.E.O. American Power & Gas",
      },
      reasons: {
        title: "Why American Power & Gas?",
        subtitle:
          "Let’s review together  some good reason to consider a career in apg.",
        grid: [
          {
            title: "Are you paid what you are worth?",
            lead: '<span class="grid-item__lead">Earning potentials.</span>',
            text:
              "Our goal is to empower every individual to achieve a new level of earning potentials. Many of our top Staff started from less than 0. You’ll be paid to learn and supported while you’ll be starting to get results. You’ll learn skills for life, you’ll have a stable career, you’ll see real results.",
          },
          {
            title: "Are you having fun at your job?",
            lead:
              '<span class="grid-item__lead">Incentives for employees every week.</span>',
            text:
              "We love to play, so we offer incentives, games, rewards and bonuses every week to make it fun and enjoyable to be together. We work hard, so we play hard.",
          },
          {
            title: "Are you living the lifestyle you want?",
            lead:
              '<span class="grid-item__lead">The transformation from broke to wealthy.</span>',
            text:
              "Our staff came from all walks of life, but they had one thing in common, the willingness to work hard for their future. They wanted a bright future for themselves and their families… and those that choose that path got it. We had single mothers that came in and became 6 figures earners, we have 23year old college students that are currently buying their second home as investment, we have staff that needed a second chance in life and now are leaders in our group. We believe in you and in your potential and we’ll help you get the life you want.",
          },
          {
            title: "Ever concerned about the future?",
            lead: '<span class="grid-item__lead">Stability.</span>',
            text:
              "You need to choose an industry that will continue to be there for the years to come. The energy industry is the perfect one. There won't be a time in which we won’t be needing electricity, the demand keeps growing, so choosing a career in this industry is a career that will last no matter what.",
          },
          {
            title: "Feel you should be reaching bigger financial results?",
            lead:
              '<span class="grid-item__lead">Entrepreneur’s mentality and earning levels without the investment.</span>',
            text:
              "At American Power & Gas we’ll enable you to operate like an entrepreneur, but without the risk and the investments. We’ll give you the tools, the equipment, the training and the supporto. We need from you time, ethical approach, and dedication. Your earning potential can really be life changing.",
          },
          {
            title: "Ever had a company that wants to invest in you?",
            lead: '<span class="grid-item__lead">Skills for life.</span>',
            text:
              "We will! We’ll pay you to learn. You’ll learn skills for life, such as determination, control, sales, team work, focus and much more. Those are skills that can be learned and we’ll teach them to you.",
          },
          {
            title: "You will be part of a real team.",
            lead: "",
            text:
              "American Power and Gas count more than 300 employed with a worldwide presence. We are a real team, we know each other and we are in harmony with each other. We are like minded crazy people that think the world can be a better place and that we can do our part to make it so. Part of our mission is to empower our staff to get an ideal life, one where they are fulfilling their goals, providing for their families and contribute to society through their work.",
          },
        ],
      },
      closer: {
        title: "Want to know more about American Power & Gas?",
        subtitle:
          "We earn respect through results, build businesses through service, take care of our team like friends would do.",
        btn: "Find out More",
        href: "/team",
      },
    },
    team: {
      header: {
        intro: 'American Power & Gas <span class="color-secondary">Team</span>',
        title: "Meet our team",
        paragraphs: [
          "We earn respect through results, build our business through service, take care of our team like friends would do.",
        ],
        video: "https://www.youtube.com/embed/hZ5hntxmuHM?rel=0",
        button: "Get started",
      },
      testimonials: {
        title: "How’s working at American Power & Gas?",
        paragraphs: [
          "The team, the experience, the transformation and the results. Hear about earning potentials, Career potentials and more from your future teammates.",
        ],
        videos: [
          {
            name: "Rodney",
            position: "Sales Representative | currently employed",
            url: "https://www.youtube.com/embed/lYtW9yeoRt8?rel=0",
            thumbnail: rodneyPicture,
          },
          {
            name: "Brian",
            position: "Sales Representative | currently employed",
            url: "https://www.youtube.com/embed/pmTl0shC3Ro?rel=0",
            thumbnail: brianPicture,
          },
          {
            name: "Stephanie",
            position: "Sales Representative | currently employed",
            url: "https://www.youtube.com/embed/kPjIDIBy2lA?rel=0",
            thumbnail: stephaniePicture,
          } /*,
                    {
                        "name": 'Chad',
                        "position": "Sales Representative | currently employed",
                        "url": "https://www.youtube.com/embed/ikzaont2C2o?rel=0",
                        "thumbnail": chadPicture
                    }*/,
        ],
      },
      perks: {
        title: "What Should you Expect from American Power & Gas?",
        text:
          "We believe in giving and we believe that “To Lead” is “To Serve”.<br /> So here’s what you can expect from us.",
        grid: [
          {
            title: "Stay well",
            text:
              "The safety of our staff is the most important thing for us. Our offices are constantly disinfected and we take any kind of precaution to ensure the safety and wellbeing of our team and their families.",
          },
          {
            title: "Earning potential",
            text:
              "Your ability to support your family is not enough for us. We are not here to survive, we are here to thrive and make the world a better place in doing so. So here at American Power Gas you can expect to get a real chance to make a great living, transform your life and make real friends. While you are starting in American Power & Gas you will have access to our learning program for free. You will learn new skills that you will use in your life all the time. The value of the training program is based on the skills of 30 years of experience accumulated and it is priceless.",
          },
          {
            title: "Work with a purpose",
            text:
              'We work to create a better society. You’ll be working for a company that is focused on providing renewable energy to its customers while also launching various charitable campaigns throughout the year. <a href="https://www.youtube.com/watch?v=55_Nq-9RpII&t=101s">In 2020 we provided over 1.5 million meals to families</a> in need and in the previous years we have collaborated with <a href="https://www.youtube.com/watch?v=elKbNyiqM0o">toys for tots providing over 20,000 presents for kids</a> in need, <a href="https://www.youtube.com/watch?v=8UFfw9YE9G8">habitat for humanity providing energy saving appliances for 100 homes</a> and many other charitable organizations.',
          },
          {
            title: "Constant help, Support and Training",
            text:
              "When you join AP&G you are joining a team committed to your success. We have our experts mentoring, coaching and helping you along every step of the way.",
          },
          {
            title: "Career opportunities",
            text:
              "We promote from the inside, we are constantly looking for executive material amongst our ranks. All our supervisors, Managers and Directors come from the inside and grew to that position. We look forward having you in our team and giving you the chance to prove yourself and earn higher and higher positions. ",
          },
          {
            title: "Paid time off",
            text:
              "Our full time employees get paid time off and their ability to get time off increases as time goes by. On top of that we, periodically award vacations to our best producing staff members… Not bad right?",
          },
          {
            title: "Healthcare benefits",
            text:
              "As American Power & Gas employee you’ll be able to enroll in a comprehensive insurance for health, dental and vision benefits.",
          },
        ],
      },
      whatWeNeed: {
        title: "What We Expect From You",
        text:
          "We pride ourselves in having one of the best teams in the industry. So we expect you to learn our culture and become part of it. American Power & Gas is a WE ARE rather than an IT IS. We “create” the American Power & Gas dream every day.",
        skills: [
          "Hard work and dedication",
          "Willingness to learn and constantly improve yourself",
          "Ability and desire to work in a fast paced dynamic environment",
          "Goal oriented mentality",
          "Desire to be part of a team",
          "Punctuality, precision and dependability",
        ],
      },
      superHero: {
        intro: "WE LOVE PEOPLE THAT HAVE:",
        characteristics: "Positive attitude - Professionalism - Honesty",
        slogan: "We need a super hero.",
        question: "Is that YOU?",
      },
      closer: {
        title: "Do you want to find out more about American Power and Gas?",
        subtitle: "Get in touch",
        btn: "Click Here",
        href: "/positions",
      },
      quote: {
        intro: "An Equal Opportunity Employer",
        text:
          "AP&G does not discriminate based on race, religion, color, national origin, sex, sexual orientation, gender identity, gender expression, age, status as a protected veteran, status as an individual with a disability, genetic information, political views or activity, or other applicable legally protected characteristics.",
      },
      support: {
        title: "Support to the team during COVID-19",
        text:
          "We are proud to say that we were able to help and support the team even during the devastating moments of COVID -19. ",
        caption: "Remote sales reps during COVID-19",
      },
    },
    company: {
      header: {
        intro:
          'American Power & Gas <span class="color-secondary">Company</span>',
        title: "Much more than an energy company",
        paragraphs: [],
        video: "https://www.youtube.com/embed/8s2YL_dUvw4?rel=0",
        button: "Get started",
      },
      reasons: {
        title: "Our Mission at American Power & Gas",
        subtitle: "What drives us every day",
        quote: {
          text:
            "To build a corporation which is led by highly able and caring individuals, who never lose sight of the suffering of the world around us; ensuring that every staff member and their families are given shelter from it—and providing the needed monetary energy to the most effective charity organizations for their positive purposes.",
          name: "Tom Cummings",
          position: "C.E.O. American Power & Gas",
        },
      },
      socialCaring: {
        title:
          "Our latest Campaigns donated over 1,500,000 meals to communities in need",
        subtitle: "Our team gives back to the community.",
        video: {
          name: "Brownsville-Brooklyn, NY",
          position:
            "1.5 Million meals donated to the hungry across the North East. Brownsville in Brooklyn NY was the latest of our initiatives. Thanks to the help of celebrity and international recording artist Stacy Francis and the Universal Church of God we were able to bring relief to the community.",
          url: "https://www.youtube.com/embed/55_Nq-9RpII",
        },
        location: {
          title: "American Power and Gas",
          card: [
            { title: "incredible staff members", number: "220" },
            { title: "square foot facility", number: "36,000" },
            {
              title:
                "bln tons pollution removed thanks to renewable energy delivered.",
              number: "1",
            },
          ],
        },
      },
      leadership: {
        title: "Meet the President & C.E.O",
        leaders: {
          president: {
            name: "Jim Bridgeforth",
            position: "President of Amercan Power and Gas",
            bio:
              "<p>American Power & Gas (AP&G) was founded by Mr. Jim Bridgeforth and Mr. Tom Cummins in 2011 with a clear, simple vision:</p><p>“I wanted to build an organization that could increase the conditions of all my employees and change their lives while bettering the world around us. This is why I have built a whole organization around the idea that here my staff can create a career, knowing they're simultaneously contributing to preserving the planet as we broaden our vision for a sustainable society into new territories, near and far. We're also continuously working to grow our company's renewable energy portfolio for our customers.”</p>",
          },
          ceo: {
            name: "Tom Cummins",
            position: "CEO of American Power and Gas",
            bio: /*html*/ `<p>Mr. Cummins is a veteran in the energy industry and founded one the most successful energy sales companies in the U.S. “Consumer Sales Solutions”, which he ran as CEO.</p>
                        <p>His desire to create a better experience for customers while accelerating the adoption of 100% Green Energy were the driving forces behind the creation of American Power & Gas.</p>
                        <p><i>“We are a purpose driven company, and that purpose is shifting our market base from death and towards life.<br />
                        It is in our DNA.<br />
                        It is why we go to work every morning.”</i></p>
                        `,
          },
        },
      },
      // "stories": {
      //     "title": "Stories form Inside",
      //     "subtitle": "Let's review together some good reasons to consider a career in apg",
      //     "personnel": [{
      //         "name": "Michael",
      //         "position": "VP Sales",
      //         "bio": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis."
      //     }, {
      //         "name": "Vee",
      //         "position": "Director of Training",
      //         "bio": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis."
      //     }],
      //     "testimonials": [{
      //         "name": "Brian",
      //         "position": "Sales Representative | currently employed",
      //         "url": "https://www.youtube.com/embed/xAuCUN3bEmI"
      //     }, {
      //         "name": "Rodney",
      //         "position": "Sales Representative | currently employed",
      //         "url": "https://www.youtube.com/embed/xAuCUN3bEmI"
      //     }]
      // },
      goals: {
        title: "What is the Goal of APG",
        subtitle:
          "Our main focus is to provide energy solutions sourced from renewable energy across the USA, and in doing that help our team to have a great life. A life of purpose and success.",
        image: {
          title: "A Full Green Energy World",
          //"subtitle": "We like to go to sleep everyday knowing we did our part in making this world a better place.",
        },
        video: "https://www.youtube.com/embed/61a9V660tD8?rel=0",
        text:
          "We like to go to sleep everyday knowing we did our part in making this world a better place.",
      },
      closer: {
        title: "Are you the missing part of our team?",
        cta: "Get started",
      },
    },
    training: {
      header: {
        intro:
          'American Power & Gas <span class="color-secondary">Training</span>',
        title: "Your success is our success.",
        paragraphs: [
          "Give us a call to learn more. It’s easy  to start a brand new future at AP&G.",
        ],
        button: "Get started",
      },
      trainingGrid: [
        {
          svg: "/training-grid/1.svg",
          title: "You start by learning the basics, we call it ORIENTATION",
          text:
            "We give you an overview of the sales process, and the buying journey of our customers. We get you in the right mindset to take on your future head on.",
        },
        {
          svg: "/training-grid/2.svg",
          title:
            "Once you graduate from Orientation you move to APPRENTICESHIP",
          text:
            "Our experts teach you everything you need to know using role-play and then listening in on your calls and help you along every step of the process with constructive feedback and suggestions to overcome challenges.",
        },
        {
          svg: "/training-grid/3.svg",
          title: "YOU NOW GRADUATE TO PRO.",
          text:
            "After two weeks of successful completion of our training program you are ready to take on (and earn) as much you want. We reward hard work and success is yours for the taking.",
        },
      ],
      experience: {
        title: "Our Training comes from 36 years of experience.",
        intro:
          "We have fine-tuned and honed in the most efficient way to do what we do; and we are exceptionally good at teaching others how to do it.",
        grid: [
          {
            title: "Every single step is defined",
            text:
              "We Understand it’s a process. We break it down into easy to learn and manage steps from top to bottom.",
          },
          {
            title: "Expert supervisors are ready to help",
            text:
              "All of our support management team were once sales associates just like you. This means that they have faced the exact same challenges and overcome them already. This means you get to benefit from their experience as you rise with us.",
          },
          {
            title: "Made of succesful actions over time",
            text:
              "Our process is made up of our victories. We teach you what works. Because we KNOW it works. Follow our format and you WILL win.",
          },
        ],
      },
      trainingTestimonials: {
        title: "What the staff is saying",
        intro:
          "Let’s review together  some good reason to consider a career in apg.",
        video: {
          name: "Lucie",
          position: "Sales Representative | currently employed",
          url: "https://www.youtube.com/embed/xAuCUN3bEmI?rel=0",
        },
      },
      card: {
        title: "We are hiring over the phone",
        cta: "Call now",
        tel: "tel:+1 727 733 8520",
      },
      closer: {
        title: "This is your opportunity!",
        text: "We are going places. Come with us.",
      },
    },
    faq: {
      header: {
        intro: 'American Power & Gas <span class="color-secondary">FAQ</span>',
        title: "Questions? We have answers",
        paragraphs: [],
      },
      questions: [
        {
          question: "Where are you located?",
          answer: "We are located in Seminole, FL at 10601 Belcher Road South.",
        },
        {
          question: "Is this a work from home opportunity?",
          answer:
            "No; we offer a great in-office environment and company culture.",
        },
        {
          question: "What are your hours? ",
          answer:
            "We work Monday - Friday from 8:30 to 5 p.m. There’s breaks and a lunch break. We don’t work nights or weekends.",
        },
        {
          question: "Do you offer medical insurance?",
          answer:
            "Yes, our full time employees can choose to take advantage of our medical, dental and vision insurance benefits.",
        },
      ],
      closer: {
        title: "Ready to start at AP&G?",
        subtitle: "Contact us today and begin a new future tomorrow!",
        btn: "Get Started",
        href: "/positions",
      },
    },
  },
  positions: {
    title: "Positions",
    description: "Here are our open positions!",
  },
  contacts: {
    title: "Contacts",
    description: "Get in touch and let's discuss your application!",
  },
  footer: {
    top: {
      title: "Follow us on social media",
      icons: [
        {
          type: "Facebook",
          link: "https://www.facebook.com/AmericanPowerandGas",
        },
        {
          type: "Instagram",
          link: "https://www.instagram.com/americanpowerandgascareers/",
        },
        {
          type: "Linkedin",
          link: "https://www.linkedin.com/company/american-power-&-gas-llc/",
        },
        {
          type: "YouTube",
          link: "https://www.youtube.com/channel/UCXjN6OnXTbclD4QFTPO0kkA",
        },
        { type: "Twitter", link: "https://twitter.com/APandG" },
      ],
    },
    center: [
      {
        title: "More About Us",
        items: [
          '<a href="#" class="footer__link">About Us</a>',
          '<a href="#" class="footer__link">Contact Us</a>',
        ],
      },
      {
        title: "Related Informations",
        items: [
          '<a href="https://americanpowerandgas.com/" class="footer__link">AP&G Website</a>',
        ],
      },
      {
        title: "Equal Opportunity",
        items: [
          "We take affirmative action to ensure equal opportunityfor all applicants without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, Veteran status, or other legally protected characteristics.",
        ],
      },
    ],
  },
};

export default content;
