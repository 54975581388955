import React from 'react';

import Divider from '../components/Divider.jsx';
import SocialMediaIcon from '../components/SocialMediaIcon.jsx';

import content from '../../content/text';
const { footer: {top: { title, icons }, center}} = content;

export default function Footer() {
    return <footer className="footer">
        <div className="footer__container">
            <div className="social-media-icons">
                <span className="footer__top">{title}</span>
                <div className="social-media-icons__container">
                    {icons.map((icon, i) => <SocialMediaIcon {...icon} key={i} />)}
                </div>
            </div>
            <Divider />
            <div className="footer__columns-container">
                {center.map(({title, items}, i) => <div className="footer__column" key={i}>
                    <h5 className="footer__small-title">{title}</h5>
                    <div className="footer__items-container">
                        {items.map((item, i) => <span dangerouslySetInnerHTML={{__html: item}} key={i} />)}
                    </div>
                </div>)
                }
            </div>
            <Divider />
        </div>
    </footer>
}

