import React from 'react';

import svg from '../../../images/training/training-closer-image.svg';

export default function TrainingCloser({title, text}) {
    return <section className="training-closer">
        <div className="training-closer__container">
            <h2 className="training-closer__title">{title}</h2>
            <p className="training-closer__text">{text}</p>
        </div>
        <img src={svg} className="training-closer__image" alt="Person working with a supervisor" />
    </section>
}