import React, { useContext } from 'react';

import { Link } from "react-router-dom";

import VideoContext from '../../../context/VideoContext';

import playIcon from '../../../images/svg/play-icon.svg';
import apgLogo from '../../../images/home/american-power-and-gas-standard-logo.png'

export default function Header({ title, thumbnail, paragraphs, intro, button, video, additionalClasses, grantCardone, svg, imageSvg }) {
    const [, setPlaying] = useContext(VideoContext);
    function handleClick() {
        setPlaying({ active: true, url: video });
    }

    return <>
    <Link to="/" className="navigation__item header__logo"><img className="navigation__item-logo" src={apgLogo} alt="American Power and Gas logo" /></Link>
    <section className={additionalClasses ? `header ${additionalClasses}` : 'header'}>
        <div className="header__text-container">
            {intro && <h2 className="header__intro" dangerouslySetInnerHTML={{__html: intro}} />}
            <h1 className="header__title" dangerouslySetInnerHTML={{__html: title}} />
            {grantCardone && <h2 className="hide-desktop">GRANT CARDONE IS IN DA HOUSE!</h2>}
            <div className="header__image-container hide-desktop">
            {svg ? <object data={thumbnail} type="image/svg+xml" className="header__image"><img src={imageSvg} alt="office drawing" className="header__image" /></object> : <img className="header__image" src={thumbnail} alt="Our staff at APG" />}
            {video && <object className="video-thumb__play-icon video-thumb__play-icon--grant-icon" onClick={handleClick}>
                <embed src={playIcon} alt="play" type="image/svg+xml" />
            </object>}
        </div>
            {paragraphs.map((paragraph, i) => <p className="header__paragraph" key={i}>{paragraph}</p>)}
            { button && <Link className="btn-primary" to="/positions">{button}</Link>}
        </div>
        <div className="header__image-container hide-mobile">
            {grantCardone && <h2>GRANT CARDONE IS IN DA HOUSE!</h2>}
            {svg ? <object data={thumbnail} type="image/svg+xml" className="header__image"><img src={imageSvg} alt="office drawing" className="header__image" /></object> : <img className="header__image" src={thumbnail} alt="Our staff at APG" />}
            {video && <object className="video-thumb__play-icon video-thumb__play-icon--grant-icon" onClick={handleClick}>
                <embed src={playIcon} alt="play" type="image/svg+xml" />
            </object>}
        </div>
    </section>
    </>
}