import React from 'react';

import GridContainer from '../../components/GridContainer.jsx';

export default function Experience({ title, intro, grid }) {
    return <section className="experience">
        <h2 className="experience__title">{title}</h2>
        <p className="experience__intro">{intro}</p>
        <GridContainer grid={grid} />
    </section>
}