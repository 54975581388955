import React from 'react';

import Divider from '../../components/Divider.jsx';

import presidentImage from '../../../images/company/leadership/president.jpg';
import ceoImage from '../../../images/company/leadership/ceo.jpg';

export default function Leadership({title, leaders: { president, ceo }}) {
    return <section className="leadership">
        <h2 className="leadership__title">{title}</h2>
        <div className="leadership__card">
            <img src={presidentImage} alt={president.name} className="leadership__card-image"/>
            <div className="leadership__card-text-container">
                <h4 className="leadership__card-name">{president.name}</h4>
                <span className="leadership__card-position">{president.position}</span>
                <p className="leadership__card-bio" dangerouslySetInnerHTML={{__html: president.bio}} />
            </div>
        </div>
        <Divider />
        <div className="leadership__card leadership__card--ceo">
            <div className="leadership__card-text-container leadership__card-text-container--ceo">
                <h4 className="leadership__card-name">{ceo.name}</h4>
                <span className="leadership__card-position">{ceo.position}</span>
                <p className="leadership__card-bio" dangerouslySetInnerHTML={{__html: ceo.bio}} />
            </div>
            <img src={ceoImage} alt={ceo.name} className="leadership__card-image leadership__card-image--ceo"/>
        </div>
    </section>
}