import React from 'react';

import content from '../../../content/text';
import superHero from '../../../images/team/team-super-hero.svg';

export default function SuperHero() {
    const { pages: { team: { superHero: { intro, slogan, question, characteristics }}}} = content;
    return <div className="super-hero__container">
        <section className="super-hero">
            <div className="super-hero__text">
                <p className="super-hero__intro">{intro}<br />{characteristics}</p>
                <p className="super-hero__slogan">{slogan}<br />{question}</p>
            </div>
            <embed src={superHero} className="super-hero__super-hero" />
        </section>
    </div>
    
}