import React from 'react';

import GridContainer from '../../components/GridContainer.jsx';

import content from '../../../content/text';
const { pages: { home: { reasons: { title, subtitle, grid }}}} = content;
export default function Reasons() {
    return <section className="reasons">
        <h2 className="reasons__title">{title}</h2>
        <p className="reasons__subtitle lead">{subtitle}</p>
        <GridContainer grid={grid} multiIcons={true} />
    </section>
}