import React from 'react';

import Header from '../sections/home/Header.jsx';
import Testimonials from '../sections/home/Testimonials.jsx';
import Features from '../sections/home/Features.jsx';
import Reasons from '../sections/home/Reasons.jsx';
import Closer from '../sections/home/Closer.jsx';

import Press from '../components/Press.jsx';
import Divider from '../components/Divider.jsx';
import QuoteCard from '../components/QuoteCard.jsx';

import content from '../../content/text';
import thumbnail from '../../images/home/home-header-video-thumb.jpg';
import quoteIcon from '../../images/svg/quote-icon.svg';

import homeTestimonial from '../../images/testimonials/home-testimonial.jpg';

export default function Home() {
    const { pages: { home: { header, testimonials, quote, closer }}} = content;
    return <>
        <Header {...header} thumbnail={thumbnail} additionalClasses="header--home" />
        <Press />
        <Divider />
        <Testimonials thumbImage={homeTestimonial} {...testimonials} />
        <Features />
        <QuoteCard {...quote} quoteIcon={quoteIcon} />
        <Reasons />
        <Closer icon {...closer} />
    </>
}