import React from 'react';

import Divider from '../../components/Divider.jsx';

import svg from '../../../images/team/team-skillset-icon.svg';
import content from '../../../content/text';

export default function SkillsGrid() {
    const { pages: { team: { whatWeNeed: { skills } } } } = content;

    return <div className="skills-grid">{skills.map((skill, i) => <div className="skills-grid__item" key={i}>
                <embed src={svg} className="skills-grid__icon" />
                <p className="skills-grid__text">{skill}</p>
                <Divider />
            </div>)
        }
    </div>
}