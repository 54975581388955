import React from 'react';

import Header from '../sections/home/Header.jsx';
import Features from '../sections/home/Features.jsx';
import Reasons from '../sections/company/Reasons.jsx';
import SocialCaring from '../sections/company/SocialCaring.jsx';
import Leadership from '../sections/company/Leadership.jsx';
// import Stories from '../sections/company/Stories.jsx';
import Goals from '../sections/company/Goals.jsx';
import CompanyCloser from '../sections/company/CompanyCloser.jsx';

import content from '../../content/text';
import thumbnail from '../../images/company/company-head-image.jpg';

export default function Company() {
    const { pages: { company: { header, reasons, socialCaring, leadership,/* stories,*/ goals, closer }}} = content;
    return <>
        <Header {...header} thumbnail={thumbnail} additionalClasses="header--company" />
        <Features />
        <Reasons {...reasons}/>
        <SocialCaring {...socialCaring}/>
        <Leadership {...leadership} />
        {/* <Stories {...stories}/> */}
        <Goals {...goals}/>
        <CompanyCloser {...closer}/>
    </>
}