import React from 'react';

import { NavLink } from "react-router-dom";

import content from '../../content/text';

export default function Overlay({style, setOpened}) {
    const { navbar: { home, team, company, training, faq, positions, contacts }, globals: { phoneNumber }} = content;

    function handleClick() {
        setOpened(false);
    }

    return <div className="overlay" style={style}>
        <div className="overlay__container">
            <NavLink exact to="/" className="navigation__item navigation__item--home" activeClassName={'navigation__item--active'} onClick={handleClick} >{home}</NavLink>     
            <NavLink exact to="/team" className="navigation__item" activeClassName={'navigation__item--active'} onClick={handleClick} >{team}</NavLink>
            <NavLink exact to="/company" className="navigation__item" activeClassName={'navigation__item--active'} onClick={handleClick} >{company}</NavLink>
            <NavLink exact to="/training" className="navigation__item" activeClassName={'navigation__item--active'} onClick={handleClick} >{training}</NavLink>
            <NavLink exact to="/faq" className="navigation__item" activeClassName={'navigation__item--active'} onClick={handleClick} >{faq}</NavLink>
            <NavLink exact to="/positions" className="navigation__item" activeClassName={'navigation__item--active'} onClick={handleClick} >{positions}</NavLink>
            <NavLink exact to="/contacts" className="navigation__item" activeClassName={'navigation__item--active'} onClick={handleClick} >{contacts}</NavLink>
            <a href={phoneNumber} className="navigation__item">{phoneNumber.split(':')[1]}</a>
        </div>
        
    </div>
}