import React from 'react';
import { NavLink } from 'react-router-dom';

import apgLogo from '../../images/home/american-power-and-gas-standard-logo.png';
import content from '../../content/text';

import Form from '../components/Form.jsx';

export default function Contacts() {
    const {contacts: { title, description }} = content;

    return <section className="contacts">
        <NavLink exact to="/" className="navigation__item header__logo" activeClassName={'navigation__item--active'}><img className="navigation__item-logo" src={apgLogo} alt="American Power and Gas logo" /></NavLink>
        <h2 className="contacts__title">{title}</h2>
        <p className="contacts__description">{description}</p>
        <div className="contacts__container">
            <Form />
        </div>
        
        </section>
}