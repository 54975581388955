import React, { useRef, useState } from 'react'
import axios from 'axios';

import { hasSpamWords } from '../../utils/spamFilter';

console.log(typeof hasSpamWords)

function isMailValid(email) {
    const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailRegex.test(email);
}

export default function Form() {
    const defaultLabelState = {shown: false, type: '', message: ''};

    const [data, setData] = useState({})
    const [labelState, setLabelState] = useState(defaultLabelState)
    const span = useRef(null);
    
    const handleCheckboxChange = (e) => {        
        if(e.target.checked) span.current.style.opacity = 1
        else span.current.style.opacity = 0

        setData({ ...data, privacy_accepted: e.target.checked })
    }

    const handleChange = (e) => {
        setData({
            ...data, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        const emailError = "The email provided is not valid"
        const spamError = "The text you are trying to send seems to contain spam content"
        e.preventDefault();
        try {
            if(!isMailValid(data.email)) throw new Error(emailError);
            else if(hasSpamWords(data.message)) throw new Error(spamError);
            else {
                const response = await axios({
                method: "post",
                url: "https://sj-api.com/externalapp/track",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: process.env.REACT_APP_SALESJET_API_KEY
                },
                data: {
                    event_name: "apg_careers_lead",
                    contact: {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        custom_attributes: {
                            "dc7627c8-b33f-eb11-a9a9-c26375d3e59f": data.subject,
                            "451d9d75-b43f-eb11-a9a9-c26375d3e59f": data.message
                        }
                    }
                }
            });
            if(response.status === 200) setLabelState({shown: true, type: "success", message: "Your message has been correctly submitted. Thank you!"})
            setTimeout(() => setLabelState(defaultLabelState), 5000)
        }
        } catch(err) {
            console.log(err)
            let message = "There has been an error in submitting your message. Please try calling us instead!";
            if(err.message === emailError || err.message === spamError) message = err.message;
            setLabelState({shown: true, type: "error", message})
            setTimeout(() => setLabelState(defaultLabelState), 5000)
        }    
    }
                
    return <>
    <form method="POST" action="/contacts" className="form" onSubmit={handleSubmit}>
    <div className="form__top-container">
    <div className="form__input-container">
            <input name="first_name" placeholder="First Name*" type="text" id="first_name" className="form__text-input" onChange={handleChange} required />
            <label className="form__label" htmlFor="first_name">First Name</label>
        </div>
        <div className="form__input-container">
            <input name="last_name" placeholder="Last Name*" type="text" id="last_name" className="form__text-input" onChange={handleChange} required />
            <label className="form__label" htmlFor="last_name">Last Name</label>
        </div>
        <div className="form__input-container">
            <input name="email" placeholder="Email*" type="email" id="email" className="form__text-input" onChange={handleChange} required />
            <label className="form__label" htmlFor="email">Email</label>
        </div>      
    </div>
    <div className="form__bottom-container">
        <div className="form__input-container">
            <input name="subject" placeholder="Subject" type="text" id="subject" className="form__text-input" onChange={handleChange} />
            <label className="form__label" htmlFor="subject">Subject</label>                    
        </div>
        <div className="form__input-container">
            <textarea name="message" id="message" cols="30" rows="10" className="form__textarea" placeholder="How can we help you?" onChange={handleChange} />
            <label className="form__label" htmlFor="message">How can we help you?</label>
        </div>
        <div className="form__checkbox-container form__checkbox-container--privacy">
            <input className="form__checkbox" type="checkbox" name="privacy_consent" id="privacy_consent" onChange={handleCheckboxChange} />
            <label className="form__checkbox-label form__checkbox-label--privacy" htmlFor="privacy_consent"><span className="form__checkbox--span"><span ref={span} className="form__checkbox--span--fill"></span></span><span className="form__privacy-label--text">I have read and agree to privacy policy and terms and conditions.<span className="red">*</span></span></label>
        </div>
    </div>
    <button className="main-button form__submit">Submit</button>
</form>
{<div className="alert" style={{ backgroundColor: labelState.type === "success" ? "green" : "red", transform: labelState.shown ? 'translateX(0)' : 'translateX(200%)'}}>{labelState.message}</div>}
</>
}

