import React from 'react';

import forbes from '../../images/svg/Forbes.svg';
import huffpost from '../../images/svg/Huffpost.svg';
import newswire from '../../images/svg/Newswire.svg';
import content from '../../content/text';
const { components: { press: { title } }} = content;

export default function Press() {

    return <div className="press">
        <h3 className="press__title">{title}</h3>
        <div className="press__logos-container">
            <embed className="press__logo" src={forbes}/>
            <embed className="press__logo" src={huffpost}/>
            <embed className="press__logo" src={newswire}/>
        </div>
    </div>
}