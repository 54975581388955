import React from 'react';

import VideoThumb from '../../components/VideoThumb.jsx';

export default function Testimonials({ title, paragraphs, videos }) {
    return <section className="testimonials">
        <h2 className="testimonials__title">{title}</h2>
        {paragraphs.map((paragraph, i) => <p className="testimonials__text lead" key={i}>{paragraph}</p>)}
        {videos.map(({name, position, url, thumbnail}, i) => <VideoThumb thumbImage={thumbnail} name={name} position={position} url={url} key={i} />)}
        
    </section>
}