import React, {useContext} from 'react';

import VideoContext from '../../../context/VideoContext';

import playIcon from '../../../images/svg/play-icon.svg';
import panorama from '../../../images/company/company-goals.jpg';
import svg from '../../../images/svg/goals.svg';

export default function Goals({title, subtitle, image, text, video}) {
    const [, setPlaying] = useContext(VideoContext);
    function handleClick() {
        setPlaying({ active: true, url: video });
    }
    return <section className="goals">
        <embed className="goals__icon" src={svg} />
        <h2 className="goals__title">{title}</h2>
        <p className="goals__subtitle">{subtitle}</p>
        <div className="goals__image-container">
            <img className="goals__image" src={panorama} alt={image.title} />
            {video && <object className="video-thumb__play-icon video-thumb__play-icon--grant-icon" onClick={handleClick}>
                <embed src={playIcon} alt="play" type="image/svg+xml" />
            </object>}
            <div className="goals-thumb__text-container">
                <span className="goals-thumb__name">{image.title}</span><br />
                <span className="goals-thumb__position">{image.subtitle}</span>
            </div>
        </div>
        <p className="goals__text">{text}</p>
    </section>
}