import React from 'react';

import Header from '../sections/home/Header.jsx';
import Questions from '../sections/faq/Questions.jsx'
import Closer from '../sections/home/Closer.jsx';

import svg from '../../images/faq/header-image.svg';
import imageSvg from '../../images/faq/faq-header-image.png';
import content from '../../content/text';

export default function Faq() {
    const { pages: { faq: { header, questions, closer }}} = content;

    return <>
        <Header {...header} thumbnail={svg} svg={true} imageSvg={imageSvg} />
        <Questions questions={questions} />
        <Closer {...closer} style={{margin: "10rem auto 12rem auto"}}/>
    </>
}