import React from 'react';

import content from '../../content/text'

export default function Positions() {
    const {positions: { title, description }} = content;

    return <section className="positions">
        <h2 className="positions__title">{title}</h2>
        <p className="positions__description">{description}</p>
        <div className="positions__container">
        {/* <!-- Start PCRecruiter WP 1.4.7--> */}
            <iframe frameBorder="0" host="https://host.pcrecruiter.net/pcrbin/jobboard.aspx?uid=CSSLocal.americanpowerandgas&amp;filter=PR6FEQ34PRSFSTS2KJGUGWTYHFQXQ2DTGVEXOSCWHF4TSVC2IU3TEL2GIZ3E4WRYHB2A" id="pcrframe" title="pcrframe" src="https://host.pcrecruiter.net/pcrbin/jobboard.aspx?uid=CSSLocal.americanpowerandgas&amp;filter=PR6FEQ34PRSFSTS2KJGUGWTYHFQXQ2DTGVEXOSCWHF4TSVC2IU3TEL2GIZ3E4WRYHB2A" style={{height: "893px", width: "100%", backgroundColor: "transparent", "border": 0, "margin": 0, padding: 0}} onLoad={window.pcrframeurl()} ></iframe>
        {/* <!-- End PCRecruiter WP --> */}
        </div>
        
        </section>
}