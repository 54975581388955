import React from 'react';

export default function TrainingGrid({trainingGrid}) {
    return <section className="training-grid">
    {trainingGrid.map(({svg, title, text}, i) => <div key={i} className="training-grid__item-container" style={i % 2 !== 0 ? {backgroundColor: "#f7f8f9"} : {backgroundColor: "initial"}}>
    <div className="training-grid__item">
        <embed className="training-grid__item-image" src={svg} type="image/svg+xml" style={i % 2 === 0 ? {gridColumn: "1 / 2"} : {gridColumn: "2 / 3", backgroundColor: "#f7f8f9"}}/>
        <h2 className="training-grid__item-title">{title}</h2>
        <p className="training-grid__item-text">{text}</p>
    </div></div>)}
</section>
}