import React from 'react';

import TeamIntro from './TeamIntro.jsx';
import SkillsGrid from './SkillsGrid.jsx';

import icon from '../../../images/team/team-what-we-need-icon.svg';
import content from '../../../content/text';

export default function WhatWeNeed() {
    const { pages: { team: { whatWeNeed: {title, text}}}} = content;
    return <div className="what-we-need__container">
        <section className="what-we-need">
            <TeamIntro {...{icon, title, text}}/>
            <SkillsGrid />
        </section>
    </div>
    
}