import React from 'react';

import GridItem from './GridItem.jsx';

import gridIcon from '../../images/team/team-grid-icon.svg';

export default function GridContainer({grid, multiIcons}) {

    return <div className="reasons__grid-container">
    {grid.map((gridItem, i) => <GridItem {...gridItem} icon={gridIcon} key={i} i={multiIcons && i}/>)}
    </div>
}