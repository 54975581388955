import React, { useContext } from 'react';

import VideoContext from '../../context/VideoContext';
import playIcon from '../../images/svg/play-icon.svg';

export default function VideoThumb({ thumbImage, name, position, url }) {
    const [, setPlaying] = useContext(VideoContext);
    function handleClick() {
        setPlaying({ active: true, url });
    }

    return <div className="video-thumb">
        <div className="video-thumb__image-container">
            <img className="video-thumb__thumbnail" src={thumbImage} alt="testimonial" />
            <object className="video-thumb__play-icon" onClick={handleClick}>
                <embed src={playIcon} alt="play" type="image/svg+xml" />
            </object>
        </div>
        <div className="video-thumb__text-container">
            <span className="video-thumb__name lead">{name}</span><br />
            <span className="video-thumb__position lead">{position}</span>
        </div>
    </div>
}