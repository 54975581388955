import React from 'react';

import Header from '../sections/home/Header.jsx';
import TrainingGrid from '../sections/training/TrainingGrid.jsx';
import Experience from '../sections/training/Experience.jsx';
// import TrainingTestimonials from '../sections/training/TrainingTestimonials.jsx';
import TrainingCard from '../sections/training/TrainingCard.jsx';
import TrainingCloser from '../sections/training/TrainingCloser.jsx';
import Divider from '../components/Divider.jsx';

import svg from '../../images/training/training-header.svg';
import imageSvg from '../../images/training/training-testimonials-top-image.png';
import content from '../../content/text';

export default function Training() {
    const { pages: { training: { header, trainingGrid, experience, /*trainingTestimonials,*/ card, closer }}} = content;
    return <>
    <div className="training-header-container">
        <Header {...header} thumbnail={svg} additionalClasses="header--training" svg={true} imageSvg={imageSvg}/>
    </div>
    <TrainingGrid trainingGrid={trainingGrid} />
    <Divider />
    <Experience {...experience} />
    {/* <Divider /> */}
    {/* <TrainingTestimonials {...trainingTestimonials} /> */}
    <TrainingCard {...card}/>
    <TrainingCloser {...closer}/>
</>
}