import React from 'react';

import { Link } from 'react-router-dom';

import image from '../../../images/company/company-closer.svg';

export default function CompanyCloser({title, text, cta}) {
    return <section className="company-closer">
        <h2 className="company-closer__title">{title}</h2>
        <embed src={image} alt="cartoon of people passing puzzle pieces around" className="company-closer__image"/>
        <p className="company-closer__text">{text}</p>
        <Link className="btn-primary" to="/positions">{cta}</Link>
    </section>
}