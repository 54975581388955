import React from 'react';
import Header from '../sections/home/Header.jsx';
import Testimonials from '../sections/home/Testimonials.jsx';
import Perks from '../sections/team/Perks.jsx';
import WhatWeNeed from '../sections/team/WhatWeNeed.jsx';
import SuperHero from '../sections/team/SuperHero.jsx';
import Closer from '../sections/home/Closer.jsx';
import TeamCard from '../sections/team/TeamCard.jsx';
//import Support from '../sections/team/Support.jsx';

import content from '../../content/text';
import homeThumbnail from '../../images/team/grant-cardone.jpg';

export default function Team() {
    const { pages: { team: { header, testimonials, quote,/* support,*/ closer }}} = content;

    return <>
        <Header {...header} thumbnail={homeThumbnail} grantCardone={true} additionalClasses="team-header" />
        <Testimonials {...testimonials}/>
        <Perks />
        <WhatWeNeed />
        <SuperHero />
        <Closer {...closer}/>
        <TeamCard {...quote} />
        {/* <Support {...support}/> */}
    </>


}