import React from 'react';

import VideoThumb from '../../components/VideoThumb.jsx';
import NumbersCard from '../../components/NumbersCard.jsx';

import thumbImage from '../../../images/testimonials/company-testimonial-1.jpg';

export default function SocialCaring({title, subtitle, video: {name, position, url}, location: { title: title2, description, card }}) {
    return <>
    <section className="social-caring">
        <h2 className="social-caring__title">{title}</h2>
        <p className="social-caring__subtitle lead">{subtitle}</p>
        <VideoThumb thumbImage={thumbImage} name={name} position={position} url={url} />
        <h2 className="social-caring__title--2">{title2}</h2>
    </section>
    <NumbersCard card={card} />
    </>
}