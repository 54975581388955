import React from 'react';

import quoteIcon from '../../images/svg/quote-icon.svg';

import content from '../../content/text';
const { pages: { home: { quote: { text, name, position }}}} = content;

export default function QuoteCard() {
    return <section className="quote-card">
        <div className="quote-card__card">
            <embed className="quote-card__quote-icon" src={quoteIcon} />
            <p className="quote-card__text lead">{text}</p>
            <div className="quote-card__author">
                <span className="quote-card__author--name lead">{name}</span><br />
                <span className="quote-card__author--position lead">{position}</span>
            </div>
        </div>
    </section>
}