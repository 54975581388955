import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";

import BtnPrimary from '../components/BtnPrimary.jsx';
import Overlay from './Overlay.jsx';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import content from '../../content/text';
import apgLogo from '../../images/home/american-power-and-gas-standard-logo.png'

const { navbar: { team, company, training, positions, faq, cta, contacts }, globals: {phoneNumber}} = content;

export default function Navigation() {
    const [opened, setOpened] = useState(false)
    const { pathname } = useLocation();

    const iconStyle = {fontSize: 35, zIndex: 50, position: "relative", color: (opened && 'white')}
    const phoneIconStyle = {fontSize: 25, marginRight: 5}

    function handleClick() {
        setOpened(!opened);
    }

    const mobileMenu = <>
        <div className="mobile-navigation__phone-container">
            <PhoneIcon color="p" style={phoneIconStyle}/>
            <a className="mobile-navigation__phone" href={phoneNumber}>{phoneNumber.split(":")[1]}</a>
        </div>
        
        <MenuIcon color="primary" style={iconStyle} onClick={handleClick}/>
    </>

    return <div className="nav-container" style={pathname === '/training' ? { backgroundColor: "#f7f8f9" } : {backgroundColor: 'initial'}}>
            <nav className="navigation">
            <NavLink exact to="/" className="navigation__item navigation__item--logo" activeClassName={'navigation__item--active'}><img className="navigation__item-logo" src={apgLogo} alt="American Power and Gas logo" /></NavLink>
            
            <div className="navigation__center-container">
            <NavLink exact to="/team" className="navigation__item" activeClassName={'navigation__item--active'}>{team}</NavLink>
            <NavLink exact to="/company" className="navigation__item" activeClassName={'navigation__item--active'}>{company}</NavLink>
            <NavLink exact to="/training" className="navigation__item" activeClassName={'navigation__item--active'}>{training}</NavLink>
            <NavLink exact to="/faq" className="navigation__item" activeClassName={'navigation__item--active'}>{faq}</NavLink>
            <NavLink exact to="/positions" className="navigation__item" activeClassName={'navigation__item--active'}>{positions}</NavLink>
            <NavLink exact to="/contacts" className="navigation__item" activeClassName={'navigation__item--active'}>{contacts}</NavLink>
            </div>
            {/* <a href={phoneNumber} className="navigation__item navigation__item--phone-number" activeClassName={'navigation__item--active'}>{}</a> */}
            <BtnPrimary text={phoneNumber.split(':')[1]} href={cta} />
        </nav>
        <nav className="mobile-navigation">
            {!opened ? mobileMenu : <CloseIcon style={iconStyle} onClick={handleClick} />}
            <Overlay style={opened ? {transform: "translateY(0)"} : {transform: "translateY(-100vh)"}} {...{opened, setOpened}}/>
        </nav>
    </div>
    
}