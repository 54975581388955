import React, { useState } from 'react';
import { Route, Switch } from "react-router-dom";

import VideoContext from './context/VideoContext';

import Home from './components/pages/Home.jsx';
import Team from './components/pages/Team.jsx';
import Company from './components/pages/Company.jsx';
import Training from './components/pages/Training.jsx';
import Faq from './components/pages/Faq.jsx'
import Positions from './components/pages/Positions.jsx'
import Contacts from './components/pages/Contacts.jsx'

import Navigation from './components/layout/Navigation.jsx';
import Footer from './components/layout/Footer.jsx';
import VideoPlayer from './components/components/VideoPlayer.jsx';

function App() {
  const [playing, setPlaying] = useState({ active: false, url: '' });

  return (
    <VideoContext.Provider value={[playing, setPlaying]}>
      <div className="App">
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/company" component={Company} />
          <Route exact path="/training" component={Training} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/positions" component={Positions} />
          <Route exact path="/contacts" component={Contacts} />
        </Switch>
        <Footer />
        <VideoPlayer />
      </div>
    </VideoContext.Provider>
  );
}

export default App;
