import React, { useContext } from 'react';

import VideoContext from '../../context/VideoContext';
import closeIcon from '../../images/svg/close.svg';


export default function VideoPlayer() {
    const [{ active, url }, setPlaying] = useContext(VideoContext);

    function handleClick() {
        setPlaying({ active: false, url: ''});
    }

 return <div className={`video-player${!active ? ' hidden' : ''}`}>
    <img className="video-player__close" src={ closeIcon } alt="close the video" onClick={ handleClick }/>
    <div className="video-player__iframe-container">
        <iframe className="video-player__iframe" src={url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="video" />
    </div>
 </div>
}

