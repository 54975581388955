import React from 'react';

import BtnSecondary from '../../components/BtnSecondary.jsx';

export default function TrainingCard({title, cta, tel}) {
    return <div className="training-card">
        <h2 className="training-card__title">{title}</h2>
        <BtnSecondary text={cta} href={tel} />
    </div>
}

