import React from 'react';

import { Link } from 'react-router-dom'

import image from '../../../images/home/home-bottom.svg';

export default function Closer({ icon, title, subtitle, btn, href, style }) {
    return <section className="closer" style={style ? style : {}}>
        { icon && <embed className="closer__image" src={image} /> }
        <h2 className="closer__title">{title}</h2>
        <h4 className="closer__subtitle">{subtitle}</h4>
        <Link className="btn-primary" to="/positions">{btn}</Link>
    </section>
}