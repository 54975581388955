import React from 'react';

export default function NumbersCard({card}) {
    return <div className="numbers-card">
        <div className="numbers-card__container lead">
            {card.map(({title, number}, i) => <div className="numbers-card__item" key={i}>
                <span className="numbers-card__item--number">{number}</span>
                <span className="numbers-card__item--title">{title}</span>
            </div>)}
        </div>
    </div>
}