import React from 'react';

import facebookIcon from '../../images/svg/social-icons/facebook.svg'
import instagramIcon from '../../images/svg/social-icons/instagram.svg'
import twitterIcon from '../../images/svg/social-icons/twitter.svg'
import linkedinIcon from '../../images/svg/social-icons/linkedin.svg'
import youtubeIcon from '../../images/svg/social-icons/youtube.svg'

export default function SocialMediaIcons({type, link}) {
    function setIconSrc() {
        switch(type.toLowerCase()) {
            case 'facebook':
                return facebookIcon
            case 'instagram':
                return instagramIcon
            case 'twitter':
                return twitterIcon
            case 'linkedin':
                return linkedinIcon
            case 'youtube':
                return youtubeIcon
            default:
                console.error("No icon found");
        }
            
    }
    return <a href={link} className="social-media-icon">
        <img src={setIconSrc(type)} alt={type} />
    </a>
}