import React from 'react';

import QuoteCard from '../../components/QuoteCard.jsx';
import quoteIcon from '../../../images/svg/quote-icon.svg';

export default function Reasons({title, subtitle, quote}) {
    return <section className="reasons">
            <h2 className="reasons__title">{title}</h2>
            <p className="reasons__text lead">{subtitle}</p>
            <QuoteCard quote={quote} quoteIcon={quoteIcon} />
        </section>
}