const forbiddenWords = `#1
100% more
100% free
100% satisfied
Additional income
Be your own boss
Best price
Big bucks
Billion
Cash bonus
Cents on the dollar
Consolidate debt
Double your cash
Double your income
Earn extra cash
Earn money
Eliminate bad credit
Extra cash
Extra income
Expect to earn
Fast cash
Financial freedom
Free access
Free consultation
Free gift
Free hosting
Free info
Free investment
Free membership
Free money
Free preview
Free quote
Free trial
Full refund
Get out of debt
Get paid
Giveaway
Guaranteed
Increase sales
Increase traffic
Incredible deal
Lower rates
Lowest price
Make money
Million dollars
Miracle
Money back
Once in a lifetime
One time
Pennies a day
Potential earnings
Prize
Promise
Pure profit
Risk-free
Satisfaction guaranteed
Save big money
Save up to
Special promotion
Avoid spam words that create unnecessary urgency and pressure
Act now
Apply now
Become a member
Call now
Click below
Click here
Get it now
Do it today
Don’t delete
Exclusive deal
Get started now
Important information regarding
Information you requested
Instant
Limited time
New customers only
Order now
Please read
See for yourself
Sign up free
Take action
This won’t last
Urgent
What are you waiting for?
While supplies last
Will not believe your eyes
Winner
Winning
You are a winner
You have been selected
Bulk email
Buy direct
Cancel at any time
Check or money order
Congratulations
Confidentiality
Cures
Dear friend
Direct email
Direct marketing
Hidden charges
Human growth hormone
Internet marketing
Lose weight
Mass email
Meet singles
Multi-level marketing
No catch
No cost
No credit check
No fees
No gimmick
No hidden costs
No hidden fees
No interest
No investment
No obligation
No purchase necessary
No questions asked
No strings attached
Not junk
Notspam
Obligation
Passwords
Requires initial investment
Social security number
This isn’t a scam
This isn’t junk
This isn’t spam
Undisclosed
Unsecured credit
Unsecured debt
Unsolicited
Valium
Viagra
Vicodin
We hate spam
Weight loss
Xanax
Accept credit cards
Ad
All new
As seen on
Bargain
Beneficiary
Billing
Bonus
Cards accepted
Cash
Certified
Cheap
Claims
Clearance
Compare rates
Credit card offers
Deal
Debt
Discount
Fantastic
In accordance with laws
Income
Investment
Join millions
Lifetime
Loans
Luxury
Marketing solution
Message contains
Mortgage rates
Name brand
Offer
Online marketing
Opt in
Pre-approved
Quote
Rates
Refinance
Removal
Reserves the right
Score
Search engine
Sent in compliance
Subject to…
Terms and conditions
Trial
SEO
SEM
Unlimited
Warranty
Web traffic
Work from home`.split("\n")

export function hasSpamWords(text) {
    const textToCheck = text.toLowerCase()
    let hasSpamWords = false
    for(let string of forbiddenWords) {
        if(textToCheck.includes(string.toLowerCase())) hasSpamWords = true
    }
    return hasSpamWords
}