import React from 'react';

import TeamIntro from './TeamIntro.jsx';
import GridContainer from '../../components/GridContainer.jsx';

import icon from '../../../images/team/team-perks-icon.svg'

import content from '../../../content/text';

export default function Perks() {
    const { pages: { team: { perks: { title, text, grid } } } } = content;
    return <section className="perks">
        <TeamIntro {...{ icon, title, text}} />
        <GridContainer grid={grid} />
    </section>
    
}