import React from 'react';
import GridVerticalBox from '../../components/GridVerticalBox.jsx';

import handshake from '../../../images/svg/handshake.svg';
import mountain from '../../../images/svg/mountain.svg';
import presentation from '../../../images/svg/presentation.svg';

import text from '../../../content/text';
const { pages: { home: { features }}} = text;

export default function Features() {
    function setSrc(i) {
        switch(i) {
            case 0:
                return handshake;
            case 1:
                return mountain;
            case 2:
                return presentation;
            default:
                return ''
        }
    }
    return <section className="features">
        {features.map(({title, text}, i) => <GridVerticalBox title={title} text={text} src={setSrc(i)} key={i} />)}
    </section>
}